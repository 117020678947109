<script lang="ts">
  import { toggleDisclaimerModal } from '$stores/disclaimer';
  import { Circle } from '$components/Common/Loader';
  import { HanaDisclaimer } from '$libs/user';
  import { account } from '$stores/web3';
  import { addToast } from '$stores/toast';
  import { PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
  import { IEnvironment } from '../../../types';

  export let modalOpen: boolean = false;

  let loading: boolean = false;

  const getExpiration = () => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 30);
    return expirationDate.getTime();
  };

  const handleModal = async () => {
    if (!$account.isConnected) return;

    const expiration = getExpiration();

    loading = true;

    const message = HanaDisclaimer.getMessage(expiration);
    const signature = await HanaDisclaimer.signDisclaimerMessage(message);

    if (signature) {
      if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.PRODUCTION || PUBLIC_ENVIRONMENT_STAGE === IEnvironment.STAGING) {
        const storeResponse = await HanaDisclaimer.storeDisclaimerSignature(signature, message, expiration);
        if (storeResponse.error && !storeResponse.data) {
          addToast({ message: 'Failed to store signature. Please try again.', type: 'error', dismissible: true });
          loading = false;
          // return;
        }
      }
      toggleDisclaimerModal(expiration);
      modalOpen = false;
    }

    loading = false;
  };
</script>

{#if modalOpen}
  <dialog class="modal" class:modal-open={modalOpen}>
    <div class="modal-container md:w-[390px]">
      <div class="flex flex-col w-full items-start gap-4">
        <div class="w-full">
          <div class="flex justify-between items-center align-middle">
            <div class="title-subsection-semibold">Welcome to Hana</div>
          </div>
        </div>

        <div class="modal-divider" />

        <p class="body-medium text-[16px] leading-normal">
          By connecting to your wallet and using Hana Finance, you accept the <a
            class="body-bold underline underline-offset-2"
            href="https://docs.hana.finance/others/legal-disclaimer"
            target="_blank">Terms of Use.</a>
        </p>

        <p class="body-medium text-[16px] leading-normal">
          Once accepted, this message will not show again for 30 days.
        </p>

        <div class="modal-divider" />

        {#if loading}
          <button class="primary-button btn-block w-full px-8 py-4 leading-[24px] text-[16px]" disabled>
            <Circle size="24" color="#FFFFFF" unit="px" duration="1s" />
          </button>
        {:else}
          <button class="primary-button btn-block w-full px-8 py-4 leading-[24px] text-[16px]" on:click={handleModal}
            >Agree and Sign</button>
        {/if}
      </div>
    </div>
  </dialog>
{/if}
