import { PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
import { writable } from 'svelte/store';
import { IEnvironment } from '../types';

const ITEM_TAG = `HanaFinance${PUBLIC_ENVIRONMENT_STAGE !== IEnvironment.PRODUCTION ? '_TESTNET' : ''}_DisclaimerAgreement`;

const SET_PERM_FLAG = (expiration: number) => {
  localStorage.setItem(ITEM_TAG, String(expiration));
};

const GET_FLAG = () => {
  const disclaimerExpiration = localStorage.getItem(ITEM_TAG);

  if (disclaimerExpiration) {
    const currentDate = new Date();
    if (+disclaimerExpiration >= currentDate.getTime()) return false;
    return true;
  }
  return true;
};

export const showDisclaimer = writable<boolean>(GET_FLAG());

export const toggleDisclaimerModal = (expiration: number) => {
  SET_PERM_FLAG(expiration);
  showDisclaimer.update(() => false);
};

export const updateDisclaimer = () => {
  showDisclaimer.update(() => GET_FLAG());
};
