<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';
  import { CloseIcon } from '$components/Common/Icons';
  import { generateBlockExplorerUrl } from '$libs/util/blockExplorer';

  const dispatch = createEventDispatcher();

  export let type = 'error';
  export let dismissible = true;
  export let txnHash: string = '';
  export let message = '';

  const TitleMapping: { [key: string]: string } = {
    success: 'Transaction Confirmed!',
    error: 'Transaction Failed!',
    info: 'Transaction Happening!',
  };
</script>

<article class={type} role="alert" transition:fade>
  <div class="content">
    <div class="section">
      <p class="title">{TitleMapping[type]}</p>
      <p class="text">{message}</p>
    </div>

    {#if txnHash}
      <a class="text explorer-url" href={generateBlockExplorerUrl(txnHash)} target="_blank">View on Taiko Explorer</a>
    {/if}
  </div>

  {#if dismissible}
    <button class="close" on:click={() => dispatch('dismiss')}>
      <CloseIcon width="0.8em" />
    </button>
  {/if}
</article>

<style>
  article {
    width: 335px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.08);
  }
  .error {
    background: #291415;
    border: 1px solid #e14fba;
  }
  .success {
    background: #0c1f17;
    border: 1px solid #6ac372;
  }
  .info {
    background: SkyBlue;
  }

  .title {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 25.6px */
    margin: 0;
  }

  .text {
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%; /* 19.2px */
  }

  .section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .explorer-url {
    color: #0989ff;
    text-decoration-line: underline;
  }

  button {
    color: white;
    background: transparent;
    border: 0 none;
    padding: 0;
    line-height: 1;
    font-size: 1rem;
  }
</style>
