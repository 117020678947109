import { createDisclaimer, getDisclaimer } from '$libs/service';
import { wagmiConfig } from '$libs/wagmi';
import { addToast } from '$stores/toast';
import { getAccount, signMessage, verifyMessage } from '@wagmi/core';

export class Disclaimer {
  getMessage(timestamp: number) {
    return `By connecting to your wallet and using Hana Finance, you accept the Terms of Use.\n\nOnce accepted, this message will not show again for 30 days.\n\n${timestamp}`;
  }

  async signDisclaimerMessage(message: string) {
    try {
      return await signMessage(wagmiConfig, { message });
    } catch (error) {
      addToast({ message: 'User cancelled transaction.', type: 'error', dismissible: true });
      return '';
    }
  }

  async getDisclaimerExpiration() {
    const userAccount = getAccount(wagmiConfig);
    const userAddress = userAccount?.address;

    if (!userAddress) return 0;
    return await getDisclaimer(userAddress);
  }

  async isDisclaimerValid(timestamp: number) {
    const userAccount = getAccount(wagmiConfig);
    const userAddress = userAccount?.address;

    if (!userAddress) return false;

    const expiration = await getDisclaimer(userAddress);
    return +expiration > timestamp;
  }

  async storeDisclaimerSignature(signature: string, message: string, expiration: number) {
    const userAccount = getAccount(wagmiConfig);
    const userAddress = userAccount?.address;

    if (!userAddress)
      return {
        data: null,
        error: 'No address found.',
      };

    const response = createDisclaimer(userAddress, signature, message, String(expiration));
    return response;
  }

  async verifySignature(signature: string, message: string) {
    const userAccount = getAccount(wagmiConfig);
    const userAddress = userAccount?.address;

    if (!userAddress) return false;

    return await verifyMessage(wagmiConfig, {
      address: userAddress,
      message,
      signature: signature as `0x${string}`,
    });
  }
}
