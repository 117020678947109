<script lang="ts">
  import { fetchMarketData } from '$stores/market';
  import { fetchUserData } from '$stores/user';
  import { account } from '$stores/web3';
  import { onDestroy, onMount } from 'svelte';

  const REFRESH_INTERVAL = 1_000 * 60 * 30; // 30 minutes;

  let lastActivity = Date.now();

  onMount(() => {
    document.body.addEventListener('mousemove', handleUserActivity);
    startCountdown();
  });

  onDestroy(() => {
    document.body.removeEventListener('mousemove', handleUserActivity);
  });

  const handleUserActivity = () => {
    lastActivity = Date.now();
  };

  const startCountdown = () => {
    setInterval(() => {
      const remainingTime = lastActivity + REFRESH_INTERVAL - Date.now();
      if (remainingTime < 0) {
        triggerRefreshData();
      }
    }, 1_000);
  };

  const triggerRefreshData = () => {
    fetchMarketData();
    if ($account.isConnected) {
      fetchUserData();
    }
    lastActivity = Date.now();
  };
</script>
