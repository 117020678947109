<script lang="ts">
  import '../app.css';
  import '../i18n';
  import Responsiveness from '$components/Responsiveness/Responsiveness.svelte';
  import { onMount, onDestroy } from 'svelte';
  import { fetchMarketData } from '$stores/market';
  import { page } from '$app/stores';

  import { Toasts } from '$components/Common/Toast';
  import { UserInactivity } from '$components/Common/UserInactivity';
  import { account } from '$stores/web3';
  import { fetchUserData } from '$stores/user';
  import { showDisclaimer, toggleDisclaimerModal, updateDisclaimer } from '$stores/disclaimer';
  import { DisclaimerModal } from '$components/Modal/DisclaimerModal';
  import { HanaDisclaimer } from '$libs/user';
  import { PUBLIC_ENVIRONMENT_STAGE } from '$env/static/public';
  import { IEnvironment } from '../types';

  import { beforeNavigate } from '$app/navigation';
  import { updated } from '$app/stores';

  // https://kit.svelte.dev/docs/configuration#version
  beforeNavigate(({ willUnload, to }) => {
    if ($updated && !willUnload && to?.url) {
      location.href = to.url.href;
    }
  });

  onMount(() => {
    // Set theme
    // localStorage.setItem('theme', 'light');
    const theme = localStorage.getItem('theme');
    document.documentElement.setAttribute('data-theme', 'dark'); // Set default theme
    fetchMarketData();
  });

  $: $account, fetchUserData();

  let _showDisclaimer: boolean = false;

  const checkDisclaimer = async () => {
    updateDisclaimer();
    if ($page.url.pathname === '/') return;
    if (!$account || !$account.address) {
      _showDisclaimer = false;
      return;
    }
    // if ($showDisclaimer) {
    //   if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.PRODUCTION) {
    //     const currentDate = new Date().getTime();
    //     const disclaimerExpiration = await HanaDisclaimer.getDisclaimerExpiration();
    //     toggleDisclaimerModal(disclaimerExpiration);
    //     _showDisclaimer = !(disclaimerExpiration > currentDate);
    //   } else {
    //     toggleDisclaimerModal(0);
    //     _showDisclaimer = true;
    //   }
    // } else {
    //   _showDisclaimer = $showDisclaimer;
    // }

    if (PUBLIC_ENVIRONMENT_STAGE === IEnvironment.PRODUCTION || PUBLIC_ENVIRONMENT_STAGE === IEnvironment.STAGING) {
      const currentDate = new Date().getTime();
      const disclaimerExpiration = await HanaDisclaimer.getDisclaimerExpiration();
      toggleDisclaimerModal(disclaimerExpiration);
      _showDisclaimer = !(disclaimerExpiration > currentDate);
    } else {
      if ($showDisclaimer) {
        toggleDisclaimerModal(0);
        _showDisclaimer = true;
      } else {
        _showDisclaimer = $showDisclaimer;
      }
    }
  };

  $: $account, checkDisclaimer();
</script>

<Responsiveness />
<Toasts />
<UserInactivity />
<DisclaimerModal bind:modalOpen={_showDisclaimer} />

<slot />
